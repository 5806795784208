import flatten from "lodash/flatten";
import isURL from "validator/lib/isURL";

export const dateToText = (dateInSeconds) => {
  const minutes = Math.floor(dateInSeconds / 60);
  const seconds = Math.floor(dateInSeconds % 60);

  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

  return `${formattedMinutes}:${formattedSeconds}`;
};

export const convertToValidUrl = (url) => {
  return url.startsWith("http://") || url.startsWith("https://")
    ? url
    : `https://${url}`;
};

export const minutesToString = (minutes) => {
  const minutesPastHour = minutes % 60;
  const hours = Math.floor(minutes / 60);
  let response = `${minutesPastHour}min`;
  if (hours > 0) {
    response = `${hours}hr ${response}`;
  }
  return response;
};

// 00:00:02 => 2
export const transcriptTimestampToSeconds = (timestamp) => {
  const timestampParts = timestamp.split(":");
  const response =
    parseInt(timestampParts[0]) * 3600 +
    parseInt(timestampParts[1]) * 60 +
    parseInt(timestampParts[2]);
  return response;
};

export const secondsToMinutes = (seconds) => {
  return Math.ceil(seconds / 60);
};

export const secondsToString = (seconds) => {
  if (seconds === null || seconds === undefined) {
    return "";
  }

  const minutes = secondsToMinutes(seconds);
  return minutesToString(minutes);
};

export const sourcingToolOptions = [
  {
    label: "No sourcing tool",
    value: "none",
  },
  {
    label: "LinkedIn Recruiter",
    value: "linkedin",
  },
  {
    label: "Covey",
    value: "covey",
  },
  {
    label: "Fetcher",
    value: "fetcher",
  },
  {
    label: "Gem",
    value: "gem",
  },
  {
    label: "Greenhouse interview confirmation",
    value: "greenhouse-interview-confirmation",
  },
  {
    label: "HireEZ",
    value: "hire-ez",
  },
  {
    label: "Lever candidate email",
    value: "lever-candidate-email",
  },
  {
    label: "Mixmax",
    value: "mixmax",
  },
  {
    label: "Paradox",
    value: "paradox",
  },
  {
    label: "Ashby",
    value: "ashby",
  },
];

export const getSourcingLinkWithParams = (url, sourcingTool) => {
  let urlWithParams = url;
  switch (sourcingTool) {
    case "gem":
      urlWithParams += `?p-fn={{first_name}}&p-ln={{last_name}}`;
      break;
    case "hire-ez":
      urlWithParams += `?p-fn={{%firstname%}}&p-ln={{%lastname%}}`;
      break;
    case "fetcher":
      urlWithParams += `?p-fn={{candidate_first_name}}&p-ln={{candidate_last_name}}`;
      break;
    case "covey":
      urlWithParams += `?p-fn={candidate_first_name}&p-ln={candidate_last_name}`;
      break;
    case "mixmax":
      urlWithParams += `?p-fn={{first name}}&p-ln={{last name}}`;
      break;
    case "linkedin":
      urlWithParams += `?p-fn={firstName}&p-ln={lastName}`;
      break;
    case "lever-candidate-email":
      urlWithParams += `?p-un={{Candidate full name}}&p-fn={{Candidate first name}}&p-jt={{Job posting title}}`;
      break;
    case "greenhouse-interview-confirmation":
      urlWithParams += `?p-e={{CANDIDATE_EMAIL_ADDRESS}}&p-fn={{CANDIDATE_FIRST_NAME}}&p-un={{CANDIDATE_NAME}}&p-jt={{JOB_NAME}}`;
      break;
    case "paradox":
      urlWithParams += `?p-e=#email&p-fn=#candidate-firstname&p-ln=#candidate-lastname&p-jt=#job-title`;
      break;
    case "ashby":
      urlWithParams += `?p-e={primaryPersonalEmail}&p-un={candidateFullName}`;
      urlWithParams = `{${urlWithParams}}`;
      break;
    default:
      break;
  }
  return urlWithParams;
};

export const flattenTree = (roots, childrenFunc) => {
  if (!roots || roots.length === 0) return [];
  const flattenedTree = roots
    ? flattenTree(
        flatten(
          roots.map((root) => {
            return childrenFunc(root).map((c) => {
              return {
                ...c,
                parent: root,
              };
            });
          })
        ),
        childrenFunc
      )
    : [];
  return [...roots, ...flattenedTree];
};

export const formatSalary = (
  salary,
  shouldShowHourlySalary = false,
  shouldRound = false
) => {
  if (shouldShowHourlySalary) {
    return `$${shouldRound ? Math.round(salary) : salary.toFixed(2)}`;
  } else {
    return `$${Math.floor(salary / 1000)}k`;
  }
};

export const formatSalaryRange = (
  lowerSalary,
  upperSalary,
  shouldShowHourlySalary = false,
  shouldRound = false
) => {
  let salaryString = formatSalary(
    lowerSalary,
    shouldShowHourlySalary,
    shouldRound
  );
  if (lowerSalary !== upperSalary) {
    salaryString += `-${formatSalary(
      upperSalary,
      shouldShowHourlySalary,
      shouldRound
    )}`;
  }
  if (shouldShowHourlySalary) {
    salaryString += "/hour";
  }
  return salaryString;
};

// Stolen from here: https://github.com/granteagon/move/blob/master/src/index.js
export const move = (array, moveIndex, toIndex) => {
  /* #move - Moves an array item from one position in an array to another.
     Note: This is a pure function so a new array will be returned, instead
     of altering the array argument.
    Arguments:
    1. array     (String) : Array in which to move an item.         (required)
    2. moveIndex (Object) : The index of the item to move.          (required)
    3. toIndex   (Object) : The index to move item at moveIndex to. (required)
  */
  const item = array[moveIndex];
  const length = array.length;
  const diff = moveIndex - toIndex;

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length),
    ];
  } else if (diff < 0) {
    // move right
    const targetIndex = toIndex + 1;
    return [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length),
    ];
  }
  return array;
};

// Stolen from here: https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
export const shouldUseDarkText = (bgColor) => {
  var color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  var uicolors = [r / 255, g / 255, b / 255];
  var c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179 ? true : false;
};

export const getImageUrl = (url, type, size) => {
  if (
    !url ||
    url.startsWith("data:") ||
    url.endsWith(".svg") ||
    url.endsWith(".ico") ||
    url.startsWith("/static/") ||
    url.startsWith("https://static.careerpuck.com/")
  ) {
    return url;
  }

  const baseUrlArray = url.split(".");
  baseUrlArray.pop();

  if (size) {
    baseUrlArray.push(size);
  }
  if (type === "png") {
    baseUrlArray.push("png");
  } else if (type === "jpg") {
    baseUrlArray.push("jpg");
  }

  return baseUrlArray.join(".");
};

export const matchJobsToSearchTerm = (searchTerm, jobs) => {
  const substitutions = [
    ["senior", "sr"],
    ["junior", "jr"],
    ["csm", "customer success manager"],
    ["manager", "mgr"],
    ["human resources", "hr"],
    ["operations", "ops"],
    ["ae", "account executive"],
    ["ml", "machine learning"],
  ];

  function removePunctuation(text) {
    return text ? text.replace(/[^\w\s\d]/g, "") : "";
  }

  const cleanSearchTerm = removePunctuation(searchTerm.toLowerCase());

  function matchField(searchPattern, field) {
    const cleanField = removePunctuation(field);
    return cleanField && cleanField.toLowerCase().search(searchPattern) > -1;
  }

  function matchFieldWithSubstitutions(searchTerm, field) {
    let searchPattern = searchTerm;
    substitutions.forEach((substitution) => {
      const substitutionPattern = `(${substitution[0]}|${substitution[1]})`;
      const substitutionRegexp = new RegExp(substitutionPattern, "g");

      searchPattern = searchPattern.replace(
        substitutionRegexp,
        substitutionPattern
      );
    });

    if (matchField(searchPattern, field)) {
      return true;
    }

    return false;
  }

  if (cleanSearchTerm.length > 0) {
    // Filter jobs by the search term matching the job title, the location, the department
    let filteredJobs = jobs.filter((job) => {
      const titleMatch = matchFieldWithSubstitutions(
        cleanSearchTerm,
        job.title
      );
      const locationMatch = matchFieldWithSubstitutions(
        cleanSearchTerm,
        job.location
      );
      const departmentMatch = matchFieldWithSubstitutions(
        cleanSearchTerm,
        job.topDepartment?.name
      );
      return titleMatch || locationMatch || departmentMatch;
    });
    return filteredJobs;
  }

  return false;
};

export const isValidLinkedInUrl = (url) => {
  let isValid = false;
  if (isURL(url) && url.includes("linkedin.com")) {
    isValid = true;
  }
  return isValid;
};

export const isValidName = (name) => {
  // Define the regular expression pattern to exclude @
  const invalidCharPattern = /@/;

  // Test the name against the pattern
  return !invalidCharPattern.test(name);
};

// Helper function to convert object keys to lowercase
export const transformKeysToLowerCase = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    newObj[key.toLowerCase()] = obj[key];
  });
  return newObj;
};

// For converting from email/phone to contact infos
export const convertCsvPersonsToPayload = (csvPersons) => {
  // Convert email/phone to contact infos
  let csvPersonsPayload = csvPersons.map((person) => {
    let contactInfos = [];
    if (person.email) {
      contactInfos.push({
        type: "email",
        value: person.email,
        isPrimary: true,
      });
    }
    if (person.phone) {
      contactInfos.push({
        type: "phone",
        value: person.phone,
        isPrimary: true,
      });
    }

    return {
      firstName: person.firstName,
      lastName: person.lastName,
      contactInfos,
      linkedInProfileId: person.linkedInProfileId,
    };
  });
  return csvPersonsPayload;
};
