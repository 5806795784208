import * as Sentry from "@sentry/browser";

import {
  anonymousIdCookieName,
  appVersion,
  cookieDomain,
  rootCookieDomain,
  trackEndpoint,
} from "environment";

import Cookies from "js-cookie";
import env from "@beam-australia/react-env";
import i18n from "./i18n";
import isNil from "lodash/isNil";
import { nanoid } from "nanoid";
import omitBy from "lodash/omitBy";

const axios = require("axios");

const puckEvents = [
  "Visited JobPage Public",
  "JobPage Time Spent",
  "JobPage Player Pressed Play",
  "JobPage Player Time Spent",
  "JobPage Public Submitted Short Form",
  "Application Found",

  "Visited ContentPage Public",
  "ContentPage Public Time Spent",
  "ContentPage Player Pressed Play",
  "ContentPage Player Time Spent",
  "ContentPage Email Submitted",
  "ContentPage Lead Form Submitted",
  "ContentPage Application Form Submitted",
  "ContentPage Clicked Hero CTA",
  "Sourcing Page Feedback Submitted",

  "Visited JobClip Public",
  "JobClip Time Spent",
  "JobClip Player Pressed Play",
  "JobClip Player Time Spent",
];

export const getAnonymousId = () => {
  let anonymousId;

  // Check for an existing Puck anonymousId
  if (Cookies.get(anonymousIdCookieName)) {
    anonymousId = Cookies.get(anonymousIdCookieName);

    // Extend the Puck cookie every time
    Cookies.set(anonymousIdCookieName, anonymousId, {
      domain: cookieDomain,
      expires: 365,
    });
  } else {
    // Create a new Puck anonymousId
    Cookies.set(anonymousIdCookieName, nanoid(12), {
      expires: 365,
      domain: cookieDomain,
    });
  }

  return anonymousId;
};

export const getAllAnonymousIds = (newAnonymousId) => {
  let anonymousIds = [];

  if (Cookies.get(anonymousIdCookieName)) {
    anonymousIds.push(Cookies.get(anonymousIdCookieName));
  }
  if (newAnonymousId) {
    anonymousIds.push(newAnonymousId);
  }

  return anonymousIds;
};

export const replaceAnonymousId = (newAnonymousId) => {
  // Replace the Puck anonymousId
  Cookies.set(anonymousIdCookieName, newAnonymousId, {
    expires: 365,
    domain: cookieDomain,
  });
};

const preProcessTrackingData = (data) => {
  // Set default category metadata
  data = Object.assign(
    { category: "general", userAgent: window.navigator.userAgent },
    data
  );

  // set common JobPage parameters
  if (data.jobPage) {
    data = Object.assign(
      omitBy(
        {
          permalink: data.jobPage.permalink,
          jobPagePermalink: data.jobPage.permalink,
          title: data.jobPage.title,
          employer: data.jobPage.employerProfile?.name,
          employerPermalink: data.jobPage.employerProfile?.permalink,
          employerName: data.jobPage.employerProfile?.name,
          platform: data.jobPage.jobBoard.atsSourcePlatform,
          department:
            data.jobPage.departments?.[0]?.name || data.jobPage.department,
          location: data.jobPage.location,
          office: data.jobPage.offices?.[0]?.name || data.jobPage.office,
        },
        isNil
      ),
      data
    );
    delete data.jobPage;
  }

  // set common JobClip parameters
  if (data.jobClip) {
    data = Object.assign(
      omitBy(
        {
          permalink: data.jobClip.permalink,
          jobClipPermalink: data.jobClip.permalink,
          jobClipType: data.jobClip.type,
          employer:
            data.jobClip.employerProfile?.name || data.jobClip.employerName,
          employerPermalink: data.jobClip.employerProfile?.permalink,
          employerName:
            data.jobClip.employerProfile?.name || data.jobClip.employerName,
        },
        isNil
      ),
      data
    );
    delete data.jobClip;
  }

  // set common ContentPage parameters
  if (data.contentPage) {
    data = Object.assign(
      omitBy(
        {
          permalink: data.contentPage.permalink,
          contentPagePermalink: data.contentPage.permalink,
          title: data.contentPage.title,
          employer: data.contentPage.employerProfile?.name,
          employerPermalink: data.contentPage.employerProfile?.permalink,
          employerName: data.contentPage.employerProfile?.name,
          blockCount: data.contentPage.blocks?.length,
          sourceTemplateTitle: data.contentPage.type,
          type: data.contentPage.type,
        },
        isNil
      ),
      data
    );
    delete data.contentPage;
  }

  // set common User parameters
  if (data.user) {
    data = Object.assign(
      omitBy(
        {
          email: data.user.email,
          firstName: data.user.profile?.firstName,
          lastName: data.user.profile?.lastName,
        },
        isNil
      ),
      data
    );
    if (data.user.employerProfile) {
      data = Object.assign(
        omitBy(
          {
            employer: data.user.employerProfile.name,
            employerPermalink: data.user.employerProfile.permalink,
            employerName: data.user.employerProfile.name,
            subscriptionTier: data.user.employerProfile.subscriptionTier,
            isLiveWithJobPages: data.user.employerProfile.isLiveWithJobPages,
            sourcingTool: data.user.employerProfile.settings?.sourcingTool,
          },
          isNil
        ),
        data
      );
    }
    delete data.user;
  }

  // handle special query-parameters
  if (data.queryParams) {
    const mapping = {
      shareLink: "p-s",
      shareLinkFirstName: "p-fn",
      shareLinkLastName: "p-ln",
      shareLinkFullname: "p-un",
      shareLinkJobTitle: "p-jt",
      shareLinkEmail: "p-e",
      shareable: "shareable",

      // salary calc params
      query: "query",
      locationString: "locationString",
      lat: "lat",
      lng: "lng",
      isRemote: "isRemote",
      distanceMi: "distanceMi",
      searchTerm: "searchTerm",
      resultsJobTitleFamilyName: "resultsJobTitleFamilyName",
      resultsJobTitleFamilyTopJobTitle: "resultsJobTitleFamilyTopJobTitle",
      resultsQuery: "resultsQuery",
      showFamilies: "_showFamilies",
    };
    for (const key in mapping) {
      if (data.queryParams[mapping[key]]) {
        data[key] = data.queryParams[mapping[key]];
      }
    }
    if (data.shareLink) {
      data.shareLinkPermalink = data.shareLink;
    }
    delete data.queryParams;
  }

  if (data.i18n) {
    data = Object.assign(
      omitBy(
        {
          language: i18n.language,
        },
        isNil
      ),
      data
    );
    delete data.i18n;
  }

  return data;
};

const shouldBlockAnalytics = () => {
  return hasBlockingCookie() || env("DEBUG_ANALYTICS");
};

const mockAnalytics = {
  page: function (...args) {
    console.log("analytics.page", args);
  },
  track: function (eventName, data = {}, options = {}, callback) {
    data = preProcessTrackingData(data);
    console.log("analytics.track", eventName, data, options);
    callback && callback();
  },
  identify: function (userId, data = {}, options = {}, callback) {
    data = preProcessTrackingData(data);
    if (!userId) {
      userId = getAnonymousId();
    }
    console.log("analytics.identify", userId, data, options);
    callback && callback();
  },
  amplitudeDeviceId: function () {
    return "amplitude-deviceId";
  },
};

// These can't be arrow function or "...arguments" won't work
export const analytics = {
  // Page view tracking
  page: function (pageName, category, properties = {}) {
    if (shouldBlockAnalytics()) {
      return mockAnalytics.page(...arguments);
    }

    // Set default properties
    properties = Object.assign({}, properties);

    // Default pageName if not provided
    if (!pageName) {
      pageName = window.location.pathname;
    }

    // Send to Amplitude
    if (window.amplitude) {
      window.amplitude.track("Page Viewed", {
        pageName: pageName,
        category: category,
        ...properties,
      });
    }

    // Send to GA4
    if (window.gtag) {
      window.gtag("event", "page_view", {
        page_path: pageName,
        page_title: document.title,
        page_location: window.location.href,
        ...properties,
      });
    }

    // Send to HubSpot
    if (window._hsq) {
      window._hsq.push([
        "trackPageView",
        {
          path: pageName,
          title: document.title,
          location: window.location.href,
          ...properties,
        },
      ]);
    }
  },

  // Event tracking
  track: function (eventName, data = {}, options = {}, callback) {
    if (shouldBlockAnalytics()) {
      return mockAnalytics.track(...arguments);
    }

    data = preProcessTrackingData(data);

    // Send whitelisted events to Puck
    if (puckEvents.includes(eventName)) {
      const anonymousId = getAnonymousId();

      if (!data.employerPermalink) {
        console.error("Missing employerPermalink in track event");
        Sentry.captureException(
          new Error("Missing employerPermalink in track event")
        );
      } else if (!anonymousId) {
        console.error("Missing anonymousId in track event");
      } else {
        axios
          .post(`${trackEndpoint}/track`, {
            ...data,
            event: eventName,
            anonymousId,
            userAgent: window.navigator.userAgent,
          })
          .catch((error) => {
            if (error.response && error.response.status === 503) {
              console.log("Ignoring 503 error:", error);
            } else {
              console.error("Failed to send event to Puck", error);
              Sentry.captureException("Failed to send event to Puck", error);
            }
          });
      }
    }

    // Send to Amplitude
    if (window.amplitude) {
      window.amplitude.track(eventName, data);
    }

    // Send to GA4
    if (window.gtag) {
      window.gtag("event", eventName, data);
    }

    // Send to HubSpot
    if (window._hsq) {
      window._hsq.push([
        "trackCustomBehavioralEvent",
        { name: eventName, properties: data },
      ]);
    }

    if (callback) setTimeout(callback, 250);
  },

  // User identification
  identify: function (userId, data = {}, options = {}, callback) {
    if (shouldBlockAnalytics()) {
      return mockAnalytics.identify(...arguments);
    }

    data = preProcessTrackingData(data);
    data.userAgent = window.navigator.userAgent;

    options.context = options.context || {};

    // Identify the user in Amplitude
    if (window.amplitude) {
      const identifyEvent = new window.amplitude.Identify();
      Object.keys(data).forEach((key) => {
        identifyEvent.set(key, data[key]);
      });
      window.amplitude.identify(identifyEvent, {
        userId,
        deviceId: getAnonymousId(),
        appVersion,
        ...options.context,
      });
      window.amplitude.setDeviceId(getAnonymousId());
      if (userId) {
        window.amplitude.setUserId(userId);
      }
    }

    // Identify the user in HubSpot
    if (window._hsq) {
      window._hsq.push([
        "identify",
        {
          id: userId,
          ...data,
        },
      ]);
    }

    // Identify the user in Sentry
    Sentry.setUser({
      id: userId,
      ...data,
    });

    // Identify the user in FullStory
    if (global.FS) {
      const displayName =
        data.firstName || data.lastName
          ? `${data.firstName} ${data.lastName}`
          : data.email;
      global.FS.identify(userId, {
        displayName,
        ...data,
      });
    }

    if (callback) setTimeout(callback, 250);
  },

  amplitudeDeviceId: function () {
    if (shouldBlockAnalytics()) {
      return mockAnalytics.amplitudeDeviceId(...arguments);
    }

    return window.amplitude?.getDeviceId();
  },
};

const flagStorageName = (flagName) => `puck-flag-${flagName}`;

export const setFlagForced = (flagName, variantName) => {
  return localStorage.setItem(flagStorageName(flagName), variantName);
};

export const getFlagForced = (flagName) => {
  return localStorage.getItem(flagStorageName(flagName));
};

const blockingCookieName = "puck-disable-analytics";

export const setBlockingCookie = (hideHover = false) => {
  console.log("Setting analytics blocking cookie", rootCookieDomain);
  Cookies.set(blockingCookieName, JSON.stringify({ hideHover }), {
    expires: 365,
    domain: rootCookieDomain,
  });
};

export const hasBlockingCookie = () => {
  const disableCookie = Cookies.get(blockingCookieName);
  return !!disableCookie;
};

export const hasVisibleBlockingCookie = () => {
  const disableCookie = Cookies.get(blockingCookieName);
  return disableCookie && !JSON.parse(disableCookie).hideHover;
};
