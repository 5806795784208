import * as Sentry from "@sentry/browser";

import Cookies from "js-cookie";
import { invalidateSession } from "slices/auth";
import { isLoggedInCookieName } from "environment";

const axios = require("axios");
const qs = require("qs");

let store;

export const injectStore = (_store) => {
  store = _store;
};

// Setup default headers to attempt to disable client-side caching in some browsers
axios.defaults.headers = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};

// Store the error context in the request interceptor,
// so we can access it later in the response interceptor if an error is thrown
axios.interceptors.request.use((config) => {
  config.errorContext = new Error("Thrown at:");

  config.paramsSerializer = (params) => {
    return qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false,
    });
  };

  return config;
});

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.response?.status === 401 &&
      !error.config?.interceptorContext?.shouldIgnoreAuthFailure
    ) {
      store.dispatch(invalidateSession());
    }

    // grab the stacktrace we stored in the context and combine
    const originalStackTrace = error.config?.errorContext?.stack;
    if (originalStackTrace) {
      error.stack = `${error.stack}\n${originalStackTrace}`;
    }

    // rename the error, to give them more useful names in Sentry
    error.name = `Axios Error ${error.response?.status}`;

    if (error.config?.interceptorContext?.shouldCaptureAuthError !== false) {
      console.dir(error);
      Sentry.captureException(error);
    }
    return Promise.reject(error);
  }
);

//
// TODO: enable axios-retry once fully-tested
//

// const axiosRetry = require("axios-retry");

// // Increasing exponentialDelay's interval constant
// // per: https://github.com/softonic/axios-retry/issues/87
// const retryDelay = (retryNumber = 0) => {
//   const seconds = Math.pow(2, retryNumber) * 1000;
//   const randomMs = 1000 * Math.random();
//   return seconds + randomMs;
// };

// // retry on Network Error & 5xx responses, but not 500 errors
// // NOTE: differs from default @ https://github.com/softonic/axios-retry/blob/master/es/index.js#L25
// //       so that we don't retry 500 server exceptions
// const isRetryableError = (error) => {
//   return (
//     error.code !== "ECONNABORTED" &&
//     (!error.response ||
//       (error.response.status > 500 && error.response.status <= 599))
//   );
// };

// // NOTE: important that axiosRetry is setup _AFTER_ the logging interceptor setup above
// //       this is so that the retry logic is composed on top of the logging behavior
// //       and does not interfere with getting good stacktraces
// axiosRetry(axios, {
//   retries: 2,
//   retryDelay,
//   retryCondition: isRetryableError,
// });

export function isLoggedIn() {
  // return localStorage.getItem("isLoggedIn");
  return Cookies.get(isLoggedInCookieName) === "true";
}

const api = {
  async get(withCredentials, endpoint, headers, params, interceptorContext) {
    try {
      const response = await axios.get(endpoint, {
        method: "GET",
        params,
        headers: {
          ...headers,
        },
        interceptorContext,
        withCredentials,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async post(withCredentials, endpoint, headers, body) {
    try {
      const response = await axios.post(endpoint, JSON.stringify(body), {
        method: "POST",
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
        withCredentials,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async put(withCredentials, endpoint, headers, body) {
    try {
      const response = await axios.put(endpoint, JSON.stringify(body), {
        method: "PUT",
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
        withCredentials,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async patch(withCredentials, endpoint, headers, body) {
    try {
      const response = await axios.patch(endpoint, JSON.stringify(body), {
        method: "PATCH",
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
        withCredentials,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default api;
