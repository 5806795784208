import React, { useEffect, useState } from "react";
import { hasVisibleBlockingCookie, setBlockingCookie } from "utils/analytics";

import { appStage } from "environment";
// import mapValues from "lodash/mapValues";
// import omitBy from "lodash/omitBy";
import styled from "styled-components";
import theme from "styles/theme";

const HoverBox = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px;
  background-color: ${theme.colors.lime};
  z-index: 99999;
  font-size: 12px;
`;

const Event = styled.div`
  display: block;
  font-family: monospace;
  font-size: 10px;
`;

const HoverHeader = styled.div`
  font-weight: bold;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HoverButton = styled.button`
  background: none !important;
  border: none;
  padding: 5px !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
`;

const eventDisplayTimeoutSec = 10;

function AdminHoverBox({ flags }) {
  const currentDate = new Date();
  const currentDateString = currentDate.toString();

  const [events, setEvents] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    addEventListener("adminEvent", (ev) => {
      console.log(ev);
      setEvents((prevEvents) => [
        ...prevEvents,
        {
          detail: ev.detail,
          expires: Date.now() + eventDisplayTimeoutSec * 1000,
        },
      ]);
    });
    const interval = setInterval(() => {
      setEvents((prevEvents) =>
        prevEvents.filter((d) => {
          return d.expires > Date.now();
        })
      );
    }, 500);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHideClick = (ev) => {
    setBlockingCookie(true);
  };

  const onClearClick = (ev) => {
    setEvents([]);
  };

  if (!hasVisibleBlockingCookie()) {
    return null;
  }

  // eslint-disable-next-line no-unreachable
  return (
    <HoverBox>
      <HoverHeader>
        {appStage} &mdash; {currentDateString}
        {/* {flags && (
          <>
            <br />
            LD Flags: {JSON.stringify(flags)}
          </>
        )}
        {forcedFlags && (
          <>
            <br />
            Forced Flags: {JSON.stringify(forcedFlags)}
          </>
        )} */}
      </HoverHeader>
      {events && (
        <>
          {events.map((event, i) => {
            return <Event key={i}>{JSON.stringify(event.detail)}</Event>;
          })}
        </>
      )}
      <ActionsContainer>
        <div>
          <HoverButton onClick={onHideClick}>(hide)</HoverButton>
          <HoverButton onClick={onClearClick}>(clear)</HoverButton>
        </div>
        <HoverButton
          onClick={() => {
            throw new Error("Test");
          }}
        >
          (test sentry)
        </HoverButton>
      </ActionsContainer>
    </HoverBox>
  );
}

export default AdminHoverBox;
