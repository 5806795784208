// import Header, { ItemMenu } from "components/jobs/Header";
import Layout, { Wrapper } from "components/general/Layout";
import React, { Component } from "react";

import { connect } from "react-redux";
import { setBlockingCookie } from "utils/analytics";
import styled from "styled-components";
import theme from "styles/theme";

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${theme.spacing.unit * 4}px;

  ${theme.media.phone`
    align-items: flex-start;
    flex-direction: column;
  `}
`;

const HeaderText = styled.div`
  font-family: degular;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  color: ${theme.colors.gray4};
`;

export class AnalyticsBlock extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    setBlockingCookie();
  }

  render() {
    return (
      <>
        <Layout>
          {/* <Header right={<ItemMenu />} /> */}
          <Wrapper $maxWidth={540} $marginBottom={200}>
            <HeaderContainer>
              <HeaderText>We are now blocking your analytics...</HeaderText>
            </HeaderContainer>
          </Wrapper>
        </Layout>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {})(AnalyticsBlock);
