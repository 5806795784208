import _logo from "images/puck_logo.svg";
import _logo_dev from "images/puck_logo_dev.svg";
import _logo_uat from "images/puck_logo_uat.svg";
import env from "@beam-australia/react-env";

export const nodeEnv = env("NODE_ENV") || "development";

export const appStage = env("PUCK_STAGE") || "development";

export const appVersion = env("PUCK_VERSION") || "0.0.1";

export const appApiEndpoint =
  env("API_ENDPOINT") ||
  (appStage === "uat"
    ? "https://api.uat.careerpuck.com"
    : "https://api.careerpuck.com");

export const appEndpoint =
  env("APP_ENDPOINT") ||
  (appStage === "uat"
    ? "https://app.uat.careerpuck.com"
    : "https://app.careerpuck.com");

export const appProfileEndpoint =
  env("PROFILE_ENDPOINT") ||
  (appStage === "uat"
    ? "https://app.uat.careerpuck.com"
    : "https://me.careerpuck.com");

export const shortUrlDomain =
  env("DEV_URL_SHORTENER_DOMAIN") ||
  (appStage === "production" ? "puck.to" : "uat.puck.to");

export const shortUrlServiceEndpoint = `https://${shortUrlDomain}`;

export const trackEndpoint =
  env("TRACK_ENDPOINT") ||
  (appStage === "uat"
    ? "https://track.uat.careerpuck.com"
    : "https://track.careerpuck.com");

export const logo =
  nodeEnv === "development"
    ? _logo_dev
    : appStage === "uat"
    ? _logo_uat
    : _logo;

const docDomain = document.domain || "ngrok.io";
export const rootCookieDomain = docDomain.includes("ngrok.io")
  ? docDomain
  : "." + docDomain.split(".").slice(-2).join(".");
export const cookieDomain = (() => {
  if (docDomain.includes("ngrok.io")) {
    return docDomain;
  }
  const domainParts = docDomain.split(".");
  if (domainParts.length > 3) {
    return "." + domainParts.slice(-3).join(".");
  } else {
    return "." + domainParts.slice(-2).join(".");
  }
})();

export const isLoggedInCookieName =
  appStage === "production" ? "isLoggedIn" : `isLoggedIn-${appStage}`;
export const isImpersonatingCookieName =
  appStage === "production" ? "isImpersonating" : `isImpersonating-${appStage}`;
export const continueCookieName =
  appStage === "production" ? "continue" : `continue-${appStage}`;
export const anonymousIdCookieName =
  appStage === "production" ? "anonymousId" : `anonymousId-${appStage}`;

export const awsVideoWatchfolderBucket = env("AWS_VIDEO_WATCHFOLDER_BUCKET");
export const awsAudioWatchfolderBucket = env("AWS_AUDIO_WATCHFOLDER_BUCKET");
export const awsVideoProcessedBucket = env("AWS_VIDEO_PROCESSED_BUCKET");
export const awsAudioProcessedBucket = env("AWS_AUDIO_PROCESSED_BUCKET");

export const publicAssetsDomain = env("PUBLIC_ASSETS_DOMAIN");
export const embedDomain = env("EMBED_DOMAIN");

export const linkedInClientId = env("LINKEDIN_CLIENT_ID");

export const googleAnalyticsId = env("GOOGLE_ANALYTICS_ID");
export const googleClientId = env("GOOGLE_CLIENT_ID");

export const helloWidgetUrl = env("HELLO_WIDGET_URL");
export const helloWidgetApiBase = env("HELLO_WIDGET_API_BASE");

export const awsLongformFilesBucket = env("AWS_LONGFORM_FILES_BUCKET");

export const mapboxAccessToken = env("MAPBOX_ACCESS_TOKEN");

export const ipstackAccessKey = env("IPSTACK_ACCESS_KEY");

export const twilioNumber = env("TWILIO_NUMBER") || "18337270191";

export const microsoftOAuthClientId = env("MICROSOFT_OAUTH_CLIENT_ID");
export const microsoftOAuthScopes = env("MICROSOFT_OAUTH_SCOPES");
export const gmailClientId = env("GMAIL_CLIENT_ID");

export const awsDocumentWatchfolderBucket = env(
  "AWS_DOCUMENT_WATCHFOLDER_BUCKET"
);
export const awsPersonCsvImportBucket = env("AWS_PERSON_CSV_IMPORT_BUCKET");
