import React, { Component } from "react";

export class ErrorTest extends Component {
  render() {
    throw new Error("Testing Sentry integration!");

    // eslint-disable-next-line
    return <div></div>;
  }
}
