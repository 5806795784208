import Layout, { Wrapper } from "components/general/Layout";
import React, { Component } from "react";

import { analytics } from "utils/analytics";
import iconUnsupported from "images/ic_frown.png";
import styled from "styled-components";
import theme from "styles/theme";
import { withRouter } from "react-router";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  padding-top: ${theme.spacing.unit * 10}px;
`;

const IconContainer = styled.div`
  padding-bottom: ${theme.spacing.unit * 1}px;
`;

const Text = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: ${theme.colors.gray4};
`;

export class Unauthorized extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    analytics.page("Unauthorized Page");
  }

  render() {
    let text = "You don't have permission to view this page";

    return (
      <Layout>
        <Wrapper $maxWidth={600} $marginBottom={200}>
          <Container>
            <IconContainer>
              <img src={iconUnsupported} width={64} height={64} alt="" />
            </IconContainer>
            <Text>{text}</Text>
          </Container>
        </Wrapper>
      </Layout>
    );
  }
}

// This can't have withUser because it's referenced in withUser
export default withRouter(Unauthorized);
