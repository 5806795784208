import React, { Component } from "react";
import { appApiEndpoint, appStage, logo, nodeEnv } from "environment";

import styled from "styled-components";
import theme from "styles/theme";

const Page = styled.div`
  background-color: gray;
  height: 100vh;
  width: 100vw;
  position: absolute;
`;

const Container = styled.div`
  position: relative;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${(props) =>
    props.marginBottom ? `${props.marginBottom}px` : "0"};
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : "940px")};
  padding: 0 ${theme.spacing.unit * 10}px;

  ${theme.media.phone`
    padding: 0 ${theme.spacing.unit * 2}px;
  `}
`;

export class ShowEnv extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Page>
        <Container>
          <h2>Raw Environment</h2>
          <ul>
            {Object.keys(window._env).map(function (k) {
              console.log(k);
              return (
                <li>
                  <pre>
                    {k}={window._env[k]}
                  </pre>
                </li>
              );
            })}
          </ul>

          <h2>Computed Environment</h2>
          <ul>
            <li>
              <img alt="logo" width="200" src={logo} />
              <pre>logo={logo}</pre>
            </li>
            <li>
              <pre>appApiEndpoint={appApiEndpoint}</pre>
            </li>
            <li>
              <pre>nodeEnv={nodeEnv}</pre>
            </li>
            <li>
              <pre>appStage={appStage}</pre>
            </li>
          </ul>
        </Container>
      </Page>
    );
  }
}
