import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import theme from "styles/theme";
// import reset from 'styled-reset'

const GlobalStyle = createGlobalStyle`
  ${normalize}

  // html {
  //   box-sizing: border-box;
  // }
  // *, *:before, *:after {
  //   box-sizing: inherit;
  // }

  body {
    padding: 0;
    margin: 0;
    height: 100%;
    background-color: ${theme.colors.gray1};
  }

  a {
    outline-offset: 4px;
    text-decoration: none;
    color: inherit;
  }

  a[type=button] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  button {
    outline-offset: 5px;
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
  }

  h1, h2, h3, h4 {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
  }

  .ProseMirror:focus {
    outline: none;
}
`;

export default GlobalStyle;
