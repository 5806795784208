import { Redirect, Route } from "react-router-dom";
import { continueCookieName, cookieDomain } from "environment";

import Cookies from "js-cookie";
import React from "react";
import { isLoggedIn } from "utils/api";

export const PrivateRoute = ({ component: Component, data, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoggedIn()) {
          return <Component {...props} {...data} />;
        } else {
          // window.sessionStorage.setItem("continue", props.location.pathname);
          Cookies.set(continueCookieName, props.location.pathname, {
            domain: cookieDomain,
          });

          return (
            <Redirect
              to={{
                pathname: "/employers/login",
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};
