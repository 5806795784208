import {
  appApiEndpoint,
  continueCookieName,
  cookieDomain,
  isImpersonatingCookieName,
  isLoggedInCookieName,
} from "environment";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import Cookies from "js-cookie";
import api from "utils/api";

export const login = createAsyncThunk("auth/login", async (arg, thunkAPI) => {
  const withCredentials = true;

  try {
    const response = await api.post(
      withCredentials,
      `${appApiEndpoint}/v1/auth/login`,
      {},
      arg
    );
    // localStorage.setItem("isLoggedIn", true);
    // localStorage.removeItem("isImpersonating");
    Cookies.set(isLoggedInCookieName, "true", {
      domain: cookieDomain,
      expires: 365,
    });
    Cookies.remove(isImpersonatingCookieName, {
      domain: cookieDomain,
    });
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const loginGoogle = createAsyncThunk(
  "auth/loginGoogle",
  async (arg, thunkAPI) => {
    const withCredentials = true;

    try {
      const response = await api.post(
        withCredentials,
        `${appApiEndpoint}/v1/auth/google`,
        {},
        arg
      );
      // localStorage.setItem("isLoggedIn", true);
      // localStorage.removeItem("isImpersonating");
      Cookies.set(isLoggedInCookieName, "true", {
        domain: cookieDomain,
        expires: 365,
      });
      Cookies.remove(isImpersonatingCookieName, {
        domain: cookieDomain,
        expires: 365,
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async (arg, thunkAPI) => {
  clearStorage();

  const withCredentials = true;

  try {
    const response = await api.post(
      withCredentials,
      `${appApiEndpoint}/v1/auth/logout`,
      {},
      arg
    );
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (arg, thunkAPI) => {
    const withCredentials = true;

    try {
      const response = await api.post(
        withCredentials,
        `${appApiEndpoint}/v1/auth/forgot-password`,
        {},
        arg
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetForgotPassword = createAsyncThunk(
  "auth/resetForgotPassword",
  async (arg, thunkAPI) => {
    const withCredentials = true;

    try {
      const response = await api.post(
        withCredentials,
        `${appApiEndpoint}/v1/auth/forgot-password/reset`,
        {},
        arg
      );
      // localStorage.setItem("isLoggedIn", true);
      // localStorage.removeItem("isImpersonating");
      Cookies.set(isLoggedInCookieName, "true", {
        domain: cookieDomain,
        expires: 365,
      });
      Cookies.remove(isImpersonatingCookieName, {
        domain: cookieDomain,
        expires: 365,
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetClaimAccount = createAsyncThunk(
  "auth/resetClaimAccount",
  async (arg, thunkAPI) => {
    const withCredentials = true;

    try {
      const response = await api.post(
        withCredentials,
        `${appApiEndpoint}/v1/auth/claim-account/reset`,
        {},
        arg
      );
      // localStorage.setItem("isLoggedIn", true);
      // localStorage.removeItem("isImpersonating");
      Cookies.set(isLoggedInCookieName, "true", {
        domain: cookieDomain,
        expires: 365,
      });
      Cookies.remove(isImpersonatingCookieName, {
        domain: cookieDomain,
        expires: 365,
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const internalLoadUser = (shouldCaptureAuthError = true) => {
  return async (id, thunkAPI) => {
    const withCredentials = true;

    let params = {};
    // const isImpersonating = localStorage.getItem("isImpersonating");
    // if (isImpersonating) {
    //   params.isImpersonating = isImpersonating;
    // }
    const isImpersonating = Cookies.get(isImpersonatingCookieName);
    if (isImpersonating === "true") {
      params.isImpersonating = isImpersonating;
    }

    try {
      const response = await api.get(
        withCredentials,
        `${appApiEndpoint}/v1/users/${id}`,
        {},
        params,
        {
          shouldCaptureAuthError,
        }
      );
      return response;
    } catch (error) {
      // console.log(error);
      return thunkAPI.rejectWithValue(error.message);
    }
  };
};

export const loadUser = createAsyncThunk("auth/loadUser", internalLoadUser());
export const optionallyLoadUser = createAsyncThunk(
  "auth/loadUser",
  internalLoadUser(false)
);

export const impersonate = createAsyncThunk(
  "auth/impersonate",
  async (arg, thunkAPI) => {
    const withCredentials = true;

    try {
      const response = await api.post(
        withCredentials,
        `${appApiEndpoint}/v1/auth/impersonate`,
        {},
        arg
      );
      // localStorage.setItem("isLoggedIn", true);
      // localStorage.setItem("isImpersonating", true);
      Cookies.set(isLoggedInCookieName, "true", {
        domain: cookieDomain,
        expires: 365,
      });
      Cookies.set(isImpersonatingCookieName, "true", {
        domain: cookieDomain,
        expires: 365,
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const impersonateGet = createAsyncThunk(
  "auth/impersonateGet",
  async (arg, thunkAPI) => {
    const withCredentials = true;

    try {
      const response = await api.get(
        withCredentials,
        `${appApiEndpoint}/v1/auth/impersonate`,
        {},
        arg
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const initialState = {
  user: null,
  userLoading: false,
  loginLoading: false,
  isGoogleLoginLoading: false,
  forgotPasswordLoading: false,
  resetForgotPasswordLoading: false,
  resetClaimAccountLoading: false,
};

const clearStorage = () => {
  // window.sessionStorage.removeItem("continue");
  // localStorage.removeItem("isLoggedIn");
  // localStorage.removeItem("isImpersonating");
  Cookies.remove(continueCookieName, {
    domain: cookieDomain,
  });
  Cookies.remove(isLoggedInCookieName, {
    domain: cookieDomain,
  });
  Cookies.remove(isImpersonatingCookieName, {
    domain: cookieDomain,
  });
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutClient(state) {
      clearStorage();
      return initialState;
    },
    clearLoginError(state) {
      delete state["loginError"];
    },
    clearForgotPassword(state) {
      delete state["forgotPasswordAttempted"];
    },
    clearResetForgotPassword(state) {
      delete state["resetForgotPasswordSuccess"];
      delete state["resetForgotPasswordError"];
    },
    clearResetForgotPasswordError(state) {
      delete state["resetForgotPasswordError"];
    },
    clearResetClaimAccount(state) {
      delete state["resetClaimAccountSuccess"];
      delete state["resetClaimAccountError"];
    },
    clearResetClaimAccountError(state) {
      delete state["resetClaimAccountError"];
    },
    clearLoadUserError(state) {
      delete state["loadUserError"];
    },
    clearImpersonateError(state) {
      delete state["impersonateError"];
    },
    invalidateSession(state) {
      state["hasInvalidSession"] = true;
    },
    clearNotifications(state) {
      state["user"]["notifications"] = [];
    },
  },
  extraReducers: {
    [logout.fulfilled]: (state, action) => {
      return initialState;
    },
    [logout.rejected]: (state, action) => {
      return initialState;
    },
    [login.pending]: (state, action) => {
      state["loginLoading"] = true;
    },
    [login.fulfilled]: (state, action) => {
      state["loginLoading"] = false;
      state["loginSuccess"] = true;
      state["user"] = action.payload;
      state["hasInvalidSession"] = false;
    },
    [login.rejected]: (state, action) => {
      state["loginLoading"] = false;
      state["loginError"] = action.payload;
    },
    [loginGoogle.pending]: (state, action) => {
      state["isGoogleLoginLoading"] = true;
    },
    [loginGoogle.fulfilled]: (state, action) => {
      state["isGoogleLoginLoading"] = false;
      state["loginSuccess"] = true;
      state["user"] = action.payload;
      state["hasInvalidSession"] = false;
    },
    [loginGoogle.rejected]: (state, action) => {
      state["isGoogleLoginLoading"] = false;
      state["loginError"] = action.payload;
    },
    [forgotPassword.pending]: (state, action) => {
      state["forgotPasswordLoading"] = true;
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state["forgotPasswordLoading"] = false;
      state["forgotPasswordAttempted"] = true;
    },
    [forgotPassword.rejected]: (state, action) => {
      state["forgotPasswordLoading"] = false;
      state["forgotPasswordAttempted"] = true;
    },
    [resetForgotPassword.pending]: (state, action) => {
      state["resetForgotPasswordLoading"] = true;
    },
    [resetForgotPassword.fulfilled]: (state, action) => {
      state["resetForgotPasswordLoading"] = false;
      state["resetForgotPasswordSuccess"] = true;
      state["user"] = action.payload;
      delete state["resetForgotPasswordError"];
      state["hasInvalidSession"] = false;
    },
    [resetForgotPassword.rejected]: (state, action) => {
      state["resetForgotPasswordLoading"] = false;
      state["resetForgotPasswordError"] = action.payload;
      delete state["resetForgotPasswordSuccess"];
    },
    [resetClaimAccount.pending]: (state, action) => {
      state["resetClaimAccountLoading"] = true;
    },
    [resetClaimAccount.fulfilled]: (state, action) => {
      state["resetClaimAccountLoading"] = false;
      state["resetClaimAccountSuccess"] = true;
      state["user"] = action.payload;
      delete state["resetForgotPasswordError"];
      state["hasInvalidSession"] = false;
    },
    [resetClaimAccount.rejected]: (state, action) => {
      state["resetClaimAccountLoading"] = false;
      state["resetClaimAccountError"] = action.payload;
      delete state["resetForgotPasswordSuccess"];
    },
    [loadUser.pending]: (state, action) => {
      state["userLoading"] = true;
    },
    [loadUser.fulfilled]: (state, action) => {
      state["user"] = action.payload;
      state["userLoading"] = false;
      state["hasInvalidSession"] = false;
    },
    [loadUser.rejected]: (state, action) => {
      state["loadUserError"] = action.payload;
      state["userLoading"] = false;
    },
    "jobs/createJobClip/fulfilled": (state, action) => {
      const jobClip = action.payload;
      Object.assign(state["user"]["profile"], jobClip.profile); // update the "flat" profile properties from the jobClip subpayload
    },
    "jobs/updateJobClip/fulfilled": (state, action) => {
      const jobClip = action.payload;
      Object.assign(state["user"]["profile"], jobClip.profile); // update the "flat" profile properties from the jobClip subpayload
    },
    [impersonate.pending]: (state, action) => {
      state["impersonating"] = true;
      state["impersonateSuccess"] = false;
      state["impersonateError"] = false;
    },
    [impersonate.fulfilled]: (state, action) => {
      state["impersonating"] = false;
      state["impersonateSuccess"] = true;
      state["impersonateError"] = false;
    },
    [impersonate.rejected]: (state, action) => {
      state["impersonating"] = false;
      state["impersonateSuccess"] = false;
      state["impersonateError"] = action.payload.response.data;
    },
    "employers/updateSettings/fulfilled": (state, action) => {
      const settings = action.payload;
      Object.assign(state["user"]["employerProfile"]["settings"], settings);
    },
    "profile/updateProfile/fulfilled": (state, action) => {
      const profile = action.payload;
      Object.assign(state["user"]["profile"], profile);
    },
  },
});

export const {
  logoutClient,
  clearLoginError,
  clearForgotPassword,
  clearResetForgotPassword,
  clearResetForgotPasswordError,
  clearResetClaimAccount,
  clearResetClaimAccountError,
  clearLoadUserError,
  clearImpersonateError,
  invalidateSession,
  clearNotifications,
} = authSlice.actions;

export default authSlice.reducer;
