import { css } from "styled-components";

export const sizes = {
  large: {
    min: 1100,
  },
  desktop: {
    min: 992,
  },
  tablet: {
    min: 768,
    max: 991,
  },
  tabletExt: {
    min: 901,
  },
  phone: {
    min: 0,
    max: 767,
  },
  phoneExt: {
    min: 0,
    max: 900,
  },
  default: {
    min: 768,
  },
};

const theme = {
  colors: {
    black: "#2c2c2c",
    white: "#FFFFFF",
    gray1: "#F1F2F5",
    gray2: "#E5E6EB",
    gray2point5: "#A3A4A5",
    gray3: "#66676B",
    gray4: "#242424",
    gray5: "#F0F3F8",
    red: "#FF4F4F",
    orange: "#D6714F",
    yellow: "#F4B12E",
    lime: "#DCEBBD",
    lightGreen: "#4AE275",
    mint: "#CFE5E2",
    gumby: "#63C6BF",
    purple: "#6E83FF",
    blue: "#3E86C3",
    navy: "#1B1A31",
    bluePurple: "#6784FF",
    lightPurple: "#F4F6FF",
    tan: "#F5F5F5",
    lightGray: "#F5F7FA",
    green: "#0B8250",
    defaultButtonHover: "#565656",
    dashboardGray1: "#fafafa",
    dashboardGray2: "#f5f5f5",
    dashboardGray3: "#eeeeee",
    dashboardGray4: "#e0e0e0",
    dashboardGray5: "#bdbdbd",
    dashboardGray6: "#9e9e9e",
    dashboardGray7: "#757575",
    dashboardGray8: "#616161",
    dashboardGray9: "#424242",
    dashboardGray10: "#212121",
    dashboardGreen: "#0b8250",
    dashboardBlue: "#0c60ff",
    dashboardRed: "#ed4337",
  },
  gradients: {
    yellowPurple:
      "linear-gradient(92.11deg, #FFE49C 3.55%, #FFC9F3 49.52%, #816CFF 96.45%)",
    card1:
      "linear-gradient(98.85deg, rgba(255, 255, 255, 0.25) 13.96%, #FFFFFF 101.32%), linear-gradient(33deg, #FFFFFF 14.51%, rgba(255, 255, 255, 0.25) 97.3%), linear-gradient(250.65deg, #FFD4CF 0%, #FFE49E 100%)",
    card2:
      "linear-gradient(98.85deg, rgba(255, 255, 255, 0.25) 13.96%, #FFFFFF 101.32%), linear-gradient(33deg, #FFFFFF 14.51%, rgba(255, 255, 255, 0.25) 97.3%), linear-gradient(250.65deg, #ECBBF5 0%, #FFD4CF 100%)",
    card3:
      "linear-gradient(98.85deg, rgba(255, 255, 255, 0.25) 13.96%, #FFFFFF 101.32%), linear-gradient(33deg, #FFFFFF 14.51%, rgba(255, 255, 255, 0.25) 97.3%), linear-gradient(250.65deg, #A889FB 0%, #ECBBF5 100%)",
    card4:
      "linear-gradient(98.85deg, rgba(255, 255, 255, 0.25) 13.96%, #FFFFFF 101.32%), linear-gradient(33deg, #FFFFFF 14.51%, rgba(255, 255, 255, 0.25) 97.3%), linear-gradient(250.65deg, #846EFF 0%, #A889FB 100%)",
  },
  spacing: {
    unit: 8,
  },
  media: {
    phone: (...args) => css`
      @media (min-width: 0px) and (max-width: 767px),
        (orientation: landscape) and (min-width: 0px) and (max-width: 914px) {
        ${css(...args)}
      }
    `,
    phoneQuery: window.matchMedia(
      "(min-width: 0px) and (max-width: 767px), (orientation: landscape) and (min-width: 0px) and (max-width: 914px)"
    ),
    large: (...args) => css`
      @media (min-width: 767px) and (max-width: 1100px),
        (orientation: landscape) and (min-width: 914px) and (max-width: 1100px) {
        ${css(...args)}
      }
    `,
    embedded: (...args) => css`
      @media (min-width: 0px) and (max-width: 400px),
        @media (min-height: 0px) and (max-height: 375px) {
        ${css(...args)}
      }
    `,
    hoverSupported: (...args) => css`
      @media (hover: hover) {
        ${css(...args)}
      }
    `,
    hoverSupportedQuery: window.matchMedia("(hover: hover)"),
    small: (...args) => css`
      @media (min-width: 0px) and (max-width: 1279px),
        (orientation: landscape) and (min-width: 0px) and (max-width: 1279px) {
        ${css(...args)}
      }
    `,
    smallQuery: window.matchMedia(
      "(min-width: 0px) and (max-width: 1279px), (orientation: landscape) and (min-width: 0px) and (max-width: 1279px)"
    ),
    mini: (...args) => css`
      @media (min-width: 0px) and (max-width: 743px) {
        ${css(...args)}
      }
    `,
    miniQuery: window.matchMedia("(min-width: 0px) and (max-width: 743px)"),
  },
  container: {
    medium: (...args) => css`
      @container (max-width: 504px) {
        ${css(...args)}
      }
    `,
    small: (...args) => css`
      @container (max-width: 320px) {
        ${css(...args)}
      }
    `,
    extraSmall: (...args) => css`
      @container (max-width: 256px) {
        ${css(...args)}
      }
    `,
  },
  // media: Object.keys(sizes).reduce((acc, label) => {
  //   acc[label] = (...args) => css`
  //     @media (min-width: ${sizes[label].min}px)
  //       ${sizes[label].max
  //         ? ` and (max-width: ${sizes[label].max}px)`
  //         : ""} {
  //       ${css(...args)}
  //     }
  //   `
  //   return acc
  // }, {}),
  // smallerSE: (...args) => css`
  //   @media (max-device-height: 567px) {
  //   ${css(...args)}
  //   }
  // `
};

export default theme;
