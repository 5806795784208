import React, { createContext, useContext, useEffect, useState } from "react";

import { appApiEndpoint } from "environment";
import { compose } from "redux";
import io from "socket.io-client";
import withUserProp from "hocs/withUserProp";

// Create a context
const SocketContext = createContext(null);

// Custom hook to use the socket context
export const useSocket = () => {
  return useContext(SocketContext);
};

// Provider component
const SocketProvider = ({ user, children }) => {
  const [socket, setSocket] = useState(null);
  const [eventLog, setEventLog] = useState([]); // Store all events

  const onConnect = () => {
    console.log("Socket connected!");
  };

  useEffect(() => {
    if (!user) {
      console.log("Don't have a user yet");
      return;
    }
    if (user.type !== 2) {
      // don't continue if we're an admin using impersonate
      console.log("User is not an employer-user");
      return;
    }

    console.log("We have a user! Opening socket connection");

    const socketInstance = io(appApiEndpoint, {
      path: "/socket.io",
      withCredentials: true,
      query: {
        employerId: user.employerId,
      },
    });
    setSocket(socketInstance);

    socketInstance.onAny((eventName, ...args) => {
      console.log(`Received event: ${eventName}`, args);
      // Store the event in the log but limit the log length to 10
      setEventLog((prevLog) => {
        const newLog = [...prevLog, { eventName, args }];
        return newLog.slice(Math.max(newLog.length - 10, 0));
      });
    });
    socketInstance.on("connect", onConnect);

    // Clean up the connection when the component unmounts
    return () => {
      socketInstance.disconnect();
      // Also clear out any messages because the user has changed
      setEventLog([]);
    };
  }, [user]);

  const clearEventLog = () => {
    setEventLog([]);
  };

  console.log("The eventLog is: ", eventLog);

  return (
    <SocketContext.Provider value={{ socket, eventLog, clearEventLog }}>
      {children}
    </SocketContext.Provider>
  );
};

const enhance = compose(withUserProp);

export default enhance(SocketProvider);
