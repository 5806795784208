import React from "react";
import { connect } from "react-redux";

// The mapStateToProps function
function mapStateToProps(state) {
  const { auth } = state;
  return {
    user: auth.user,
  };
}

// Higher Order Component
const withUserProp = (WrappedComponent) => {
  // Return a new component connected to the Redux store
  class WithUser extends React.Component {
    render() {
      // Spread the user prop and other props to the WrappedComponent
      return <WrappedComponent {...this.props} />;
    }
  }

  // Connect the component to the Redux store
  return connect(mapStateToProps)(WithUser);
};

export default withUserProp;
