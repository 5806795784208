import api from "utils/api";
import { appApiEndpoint } from "environment";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const updateSettings = createAsyncThunk(
  "employers/updateSettings",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const payload = arg.payload;

    const withCredentials = true;
    try {
      const response = await api.put(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/settings`,
        {},
        payload
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadApplicationRemindersSettings = createAsyncThunk(
  "employers/loadApplicationRemindersSettings",
  async (arg, thunkAPI) => {
    const withCredentials = true;
    try {
      const response = await api.get(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${arg}/settings/app-reminders`
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateApplicationRemindersSettings = createAsyncThunk(
  "employers/updateApplicationRemindersSettings",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const payload = arg.payload;

    const withCredentials = true;
    try {
      const response = await api.put(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/settings/app-reminders`,
        {},
        payload
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadUsers = createAsyncThunk(
  "employers/loadUsers",
  async (arg, thunkAPI) => {
    const payload = {
      perPage: 1000,
    };

    const withCredentials = true;
    try {
      const response = await api.get(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${arg}/users`,
        {},
        payload
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const inviteUser = createAsyncThunk(
  "employers/inviteUser",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const payload = arg.payload;

    const withCredentials = true;
    try {
      const response = await api.post(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/users/invite`,
        {},
        payload
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  "employers/updateUser",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const userPermalink = arg.userPermalink;
    const payload = arg.payload;

    const withCredentials = true;
    try {
      const response = await api.put(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/users/${userPermalink}`,
        {},
        payload
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createImage = createAsyncThunk(
  "employers/createImage",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const payload = arg.payload;

    const withCredentials = true;
    try {
      const response = await api.post(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/images`,
        {},
        payload
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const keepSessionAlive = createAsyncThunk(
  "employers/keepSessionAlive",
  async (arg, thunkAPI) => {
    const withCredentials = true;
    try {
      const response = await api.post(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${arg}/session/keep-alive`
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
