import api from "utils/api";
import { appApiEndpoint } from "environment";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createPublicPerson = createAsyncThunk(
  "persons/createPublicPerson",
  async (arg, thunkAPI) => {
    const payload = arg.payload;

    const withCredentials = false;
    try {
      const response = await api.post(
        withCredentials,
        `${appApiEndpoint}/v1/public/persons`,
        {},
        payload
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const linkPublicPerson = createAsyncThunk(
  "persons/linkPublicPerson",
  async (arg, thunkAPI) => {
    const payload = arg.payload;

    const withCredentials = false;
    try {
      const response = await api.post(
        withCredentials,
        `${appApiEndpoint}/v1/public/persons/link`,
        {},
        payload
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadPersons = createAsyncThunk(
  "persons/loadPersons",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const perPage = arg.perPage;
    const cursor = arg.cursor;
    const jobClipPermalink = arg.jobClipPermalink;
    const contentPagePermalink = arg.contentPagePermalink;
    const sequencePermalink = arg.sequencePermalink;
    const shareLinkPermalink = arg.shareLinkPermalink;
    const jobPagePermalink = arg.jobPagePermalink;
    const event = arg.event;
    const searchQuery = arg.searchQuery;

    let url = new URL(
      `${appApiEndpoint}/v1/employers/${employerPermalink}/persons`
    );
    let params = new URLSearchParams();
    params.append("perPage", perPage);

    if (cursor) {
      params.append("cursor", cursor);
    }
    if (jobClipPermalink) {
      params.append("jobClipPermalink[]", jobClipPermalink);
    } else if (contentPagePermalink) {
      params.append("contentPagePermalink[]", contentPagePermalink);
    } else if (sequencePermalink) {
      params.append("sequencePermalink[]", sequencePermalink);
    } else if (shareLinkPermalink) {
      params.append("shareLinkPermalink[]", shareLinkPermalink);
    } else if (jobPagePermalink) {
      params.append("jobPagePermalink[]", jobPagePermalink);
    }
    if (event) {
      event.forEach((eventName) => {
        params.append("event[]", eventName);
      });
    }
    if (searchQuery) {
      params.append("search", searchQuery);
    }

    url.search = params.toString();

    const withCredentials = true;
    try {
      const response = await api.get(withCredentials, url.toString(), {});
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadPerson = createAsyncThunk(
  "persons/loadPerson",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const personPermalink = arg.personPermalink;

    const withCredentials = true;
    try {
      const response = await api.get(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/persons/${personPermalink}`,
        {}
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadPersonEvents = createAsyncThunk(
  "persons/loadPersonEvents",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const personPermalink = arg.personPermalink;

    const withCredentials = true;
    try {
      const response = await api.get(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/persons/${personPermalink}/events`,
        {}
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createPerson = createAsyncThunk(
  "persons/createPerson",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const payload = arg.payload;

    const withCredentials = true;
    try {
      const response = await api.post(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/persons`,
        {},
        payload
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createPersonsBatch = createAsyncThunk(
  "persons/createPersonsBatch",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const payload = arg.payload;

    const withCredentials = true;
    try {
      const response = await api.post(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/persons/batch`,
        {},
        payload
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updatePerson = createAsyncThunk(
  "persons/updatePerson",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const personPermalink = arg.personPermalink;
    const payload = arg.payload;

    const withCredentials = true;
    try {
      const response = await api.put(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/persons/${personPermalink}`,
        {},
        payload
      );
      return response;
    } catch (error) {
      console.log("Update person failed with: ", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createPersonNote = createAsyncThunk(
  "persons/createPersonNote",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const personPermalink = arg.personPermalink;
    const payload = arg.payload;

    const withCredentials = true;
    try {
      const response = await api.post(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/persons/${personPermalink}/notes`,
        {},
        payload
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updatePersonNote = createAsyncThunk(
  "persons/updatePersonNote",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const personPermalink = arg.personPermalink;
    const notePermalink = arg.notePermalink;
    const payload = arg.payload;

    const withCredentials = true;
    try {
      const response = await api.put(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/persons/${personPermalink}/notes/${notePermalink}`,
        {},
        payload
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updatePersonPersonLists = createAsyncThunk(
  "persons/updatePersonPersonLists",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const personPermalink = arg.personPermalink;
    const payload = arg.payload;

    const withCredentials = true;
    try {
      const response = await api.put(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/persons/${personPermalink}/person-lists`,
        {},
        payload
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updatePersonPersonTags = createAsyncThunk(
  "persons/updatePersonPersonTags",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const personPermalink = arg.personPermalink;
    const payload = arg.payload;

    const withCredentials = true;
    try {
      const response = await api.put(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/persons/${personPermalink}/person-tags`,
        {},
        payload
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const searchPersons = createAsyncThunk(
  "persons/searchPersons",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const payload = arg.payload;

    const withCredentials = true;
    try {
      const response = await api.post(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/persons/search`,
        {},
        payload
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadSearchAutocomplete = createAsyncThunk(
  "persons/loadSearchAutocomplete",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const payload = arg.payload;

    const withCredentials = true;
    try {
      const response = await api.post(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/persons/search/autocomplete`,
        {},
        payload
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
