import AdminHoverBox from "components/dev/AdminHoverBox";
import { Helmet } from "react-helmet";
import React from "react";
import styled from "styled-components";
import theme from "styles/theme";

export const Wrapper = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${(props) => (props.$marginTop ? `${props.$marginTop}px` : "0")};
  margin-bottom: ${(props) =>
    props.$marginBottom ? `${props.$marginBottom}px` : "200px"};
  max-width: ${(props) => (props.$maxWidth ? `${props.$maxWidth}px` : "810px")};
  padding: 0
    ${(props) =>
      props.$padding ? `${props.$padding}px` : `${theme.spacing.unit * 10}px`};
  color: ${(props) => (props.$color ? props.$color : theme.colors.black)};

  ${theme.media.phone`
    ${(props) =>
      props.$marginTopMobile ? `margin-top: ${props.$marginTopMobile}px` : ""};
      ${(props) =>
        props.$marginBottomMobile
          ? `margin-bottom: ${props.$marginBottomMobile}px`
          : ""};
    padding: 0 ${(props) =>
      props.$paddingMobile
        ? `${props.$paddingMobile}px`
        : `${theme.spacing.unit * 2}px`};
  `}

  ${theme.media.mini`
    ${(props) =>
      props.$paddingMini ? `padding: 0 ${props.$paddingMini}px` : ""};
  `}
`;

export const MobileHeader = styled.div`
  ${theme.media.phone`
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: 999;
  `}
`;

export const MobileContent = styled.div`
  ${theme.media.phone`
    margin-top: ${theme.spacing.unit * 12}px
  `}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
}`;

export const Column = styled.div`
  flex-basis: 100%;
  flex: 1;
}`;

const HeaderSpacer = styled.div`
  padding-bottom: 64px;

  ${theme.media.phone`
    padding-bottom: ${(props) => (props.isMobileHeaderHidden ? 0 : 60)}px;
  `}
`;

const Layout = ({ children, isHeaderHidden, isMobileHeaderHidden }) => {
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Helmet>
      <AdminHoverBox />
      {!isHeaderHidden && (
        <HeaderSpacer isMobileHeaderHidden={isMobileHeaderHidden} />
      )}
      {children}
    </>
  );
};

export default Layout;
