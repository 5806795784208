import "pages/App.css";
import "react-toastify/dist/ReactToastify.min.css";
import "utils/i18n";

import * as queryString from "query-string";

import { BrowserRouter, Redirect, Route } from "react-router-dom";
import React, { Component, Suspense } from "react";
import {
  appStage,
  isImpersonatingCookieName,
  isLoggedInCookieName,
  nodeEnv,
} from "environment";
import { createPublicPerson, linkPublicPerson } from "slices/persons";
import {
  getAllAnonymousIds,
  getAnonymousId,
  replaceAnonymousId,
} from "utils/analytics";

import AnalyticsBlock from "pages/AnalyticsBlock";
import Cookies from "js-cookie";
import Error404 from "components/general/Error404";
import { ErrorTest } from "pages/ErrorTest";
import FragmentSupportingSwitch from "components/router/FragmentSupportingSwitch";
import GlobalStyle from "styles/globalStyle";
import { PrivateRoute } from "components/router/PrivateRoute";
import { PuckToastContainer } from "components/general/Toast";
import { ShowEnv } from "pages/ShowEnv";
import { Unauthorized } from "pages/auth/Unauthorized";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { cookieDomain } from "environment";
import importRetry from "utils/importRetry";
import { isLoggedIn } from "utils/api";
import { keepSessionAlive } from "slices/employers";

/* PUCK ADMIN PAGES */
const Impersonate = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "admin" */
      "pages/auth/Impersonate"
    )
  )
);

/* AUTH PAGES */
const ClaimAccount = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "auth" */
      "pages/auth/ClaimAccount"
    )
  )
);
const ClaimAccountSignup = React.lazy(() =>
  import(
    /* webpackChunkName: "auth" */
    "pages/auth/ClaimAccountSignup"
  )
);
const ClaimAccountProfile = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "auth" */
      "pages/auth/ClaimAccountProfile"
    )
  )
);
const ForgotPassword = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "auth" */
      "pages/auth/ForgotPassword"
    )
  )
);
const ResetPassword = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "auth" */
      "pages/auth/ResetPassword"
    )
  )
);
const Login = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "auth" */
      "pages/auth/Login"
    )
  )
);
// const Test = React.lazy(() =>
//   importRetry(() =>
//     import(
//       /* webpackChunkName: "auth" */
//       "pages/auth/Test"
//     )
//   )
// );
const Logout = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "auth" */
      "pages/auth/Logout"
    )
  )
);

/* DASHBOARD PAGES */
const Home = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/Home"
    )
  )
);
const ShareLinkDetail = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/ShareLinkDetail"
    )
  )
);
const DashboardJobClipEdit = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/JobClipEdit"
    )
  )
);
const DashboardJobClipDetail = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/JobClipDetail"
    )
  )
);
const DashboardJobPageDetail = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/JobPageDetail"
    )
  )
);
const DashboardJobPages = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/JobPages"
    )
  )
);
const DashboardLeads = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/Leads"
    )
  )
);
const DashboardScreeningQuestions = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/ScreeningQuestions"
    )
  )
);
const DashboardScreeningQuestionsEdit = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/ScreeningQuestionsEdit"
    )
  )
);
const DashboardTextToApply = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/TextToApply"
    )
  )
);
// const DashboardPodcasts = React.lazy(() =>
//   importRetry(() =>
//     import(
//       /* webpackChunkName: "dashboard" */
//       "pages/dashboard/Podcasts"
//     )
//   )
// );
const DashboardContentLibrary = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/ContentLibrary"
    )
  )
);
const DashboardSettingsProfile = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/settings/Profile"
    )
  )
);
const DashboardSettingsAts = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/settings/Ats"
    )
  )
);
const DashboardSettingsMembers = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/settings/Members"
    )
  )
);
const DashboardSettingsSmartLinks = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/settings/SmartLinks"
    )
  )
);
const DashboardSettingsApplicationReminders = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/settings/ApplicationReminders"
    )
  )
);
const DashboardSettingsEmail = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/settings/Email"
    )
  )
);
// const DashboardSmartLinks = React.lazy(() =>
//   importRetry(() =>
//     import(
//       /* webpackChunkName: "dashboard" */
//       "pages/dashboard/SmartLinks"
//     )
//   )
// );
const DashboardTeaserEdit = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/TeaserEdit"
    )
  )
);
const DashboardTeasers = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/Teasers"
    )
  )
);
const LeverCallback = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/ats/LeverCallback"
    )
  )
);
const OutlookCallback = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/email/OutlookCallback"
    )
  )
);
const NativeSocialShare = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/share/NativeSocialShare"
    )
  )
);
// const ContentPages = React.lazy(() =>
//   importRetry(() =>
//     import(
//       /* webpackChunkName: "dashboard" */
//       "pages/dashboard/ContentPages"
//     )
//   )
// );
const ContentPageEdit = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/ContentPageEdit"
    )
  )
);
const SourcingPageDetail = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/SourcingPageDetail"
    )
  )
);
const SourcingPages = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/SourcingPages"
    )
  )
);
const SourcingPageWizard = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/SourcingPageWizard"
    )
  )
);
const SourcingPagePreview = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/SourcingPagePreview"
    )
  )
);
const InterviewGuideDetail = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/InterviewGuideDetail"
    )
  )
);
const InterviewGuides = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/InterviewGuides"
    )
  )
);
const OtherPageDetail = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/OtherPageDetail"
    )
  )
);
const OtherPages = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/OtherPages"
    )
  )
);
const ContentPageJobPageDetail = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/ContentPageJobPageDetail"
    )
  )
);
const ContentPageJobPages = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/ContentPageJobPages"
    )
  )
);
const DashboardPersonListDetail = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/PersonListDetail"
    )
  )
);
const DashboardTalentPool = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/TalentPool"
    )
  )
);
const DashboardTalentLists = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/TalentLists"
    )
  )
);
const DashboardSequenceDetail = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/SequenceDetail"
    )
  )
);
const DashboardSequences = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/Sequences"
    )
  )
);
const DashboardSequenceEdit = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "dashboard" */
      "pages/dashboard/SequenceEdit"
    )
  )
);

/* PUBLIC CANDIDATE PAGES */
const JobBoardPublic = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "job-board-public" */
      "pages/jobs/JobBoardPublic"
    )
  )
);
const JobPagePublic = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "job-page-public" */
      "pages/jobs/JobPagePublic"
    )
  )
);
const JobPageApplicationPublic = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "job-page-application-public" */
      "pages/jobs/JobPageApplicationPublic"
    )
  )
);
const JobClipPublic = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "job-clip-public" */
      "pages/jobs/JobClipPublic"
    )
  )
);
const JobClipTranscriptPublic = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "job-clip-transcript-public" */
      "pages/jobs/JobClipTranscriptPublic"
    )
  )
);
const AppReminderOptOut = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "app-reminder-opt-out" */
      "pages/jobs/AppReminderOptOut"
    )
  )
);
const SalaryCalculatorResults = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "salary-calculator" */
      "pages/salary_calculator/Results"
    )
  )
);
const SalaryCalculatorResultsLinked = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "salary-calculator" */
      "pages/salary_calculator/ResultsLinked"
    )
  )
);
const ContentPagePublic = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "content-page-public" */
      "pages/content_pages/ContentPagePublic"
    )
  )
);
const ContentPagePublicSales = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "content-page-public-sales" */
      "pages/content_pages/ContentPagePublicSales"
    )
  )
);
/* EMBED PAGE */
const Embed = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "embed" */
      "pages/embed/Embed"
    )
  )
);
/* PDF REPORT DOWNLOAD PAGE */
const PdfReportDownload = React.lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "pdf-reports" */
      "pages/pdf_reports/Download"
    )
  )
);

class App extends Component {
  // Send a keep-alive ping to the server
  componentDidMount() {
    // 25m timer
    this.keepAliveInterval = setInterval(() => {
      this.keepAlive();
    }, 25 * 60 * 1000);

    // Make sure we have a Puck anonymousId set
    getAnonymousId();

    // Link anonymous
    this._linkPublicPerson();

    // Create short link person
    this._createShareLinkPerson();
  }

  componentWillUnmount() {
    // Probably not necessary, but can't hurt
    clearInterval(this.keepAliveInterval);
  }

  _linkPublicPerson = async () => {
    const { dispatch } = this.props;

    // See if have puckAnonymousId in query params
    const queryParams = queryString.parse(document.location.search);

    if (queryParams.puckAnonymousId) {
      const anonymousId = queryParams.puckAnonymousId;

      const payload = {
        anonymousIds: getAllAnonymousIds(anonymousId),
      };

      const resultAction = await dispatch(linkPublicPerson({ payload }));
      if (linkPublicPerson.fulfilled.match(resultAction)) {
        // Replace the Puck anonymousId cookie with this new ID
        replaceAnonymousId(anonymousId);
      }
    }
  };

  handleCandidateDataSave = (candidateData) => {
    localStorage.setItem("puck-candidate", JSON.stringify(candidateData));
    this.setState({ candidateDataUnlocked: true });

    const payload = {
      anonymousIds: getAllAnonymousIds(),
      employerPermalink: this.state.contentPage.employerProfile.permalink,
      person: {
        source: "content-page",
        email: candidateData.email,
        fullName: candidateData.fullName,
      },
    };
    if (payload.anonymousIds) {
      this.props.dispatch(createPublicPerson({ payload }));
    }

    this.setupPageWithCandidateData(candidateData);
  };

  _createShareLinkPerson = async () => {
    const { dispatch } = this.props;

    // See if have any share link params
    const queryParams = queryString.parse(document.location.search);

    if (
      (queryParams["p-fn"] ||
        queryParams["p-ln"] ||
        queryParams["p-un"] ||
        queryParams["p-e"]) &&
      queryParams["p-s"]
    ) {
      const payload = {
        anonymousIds: getAllAnonymousIds(),
        shareLinkPermalink: queryParams["p-s"],
        person: {
          source: "share-link",
        },
      };
      if (queryParams["p-fn"]) {
        payload.person.firstName = queryParams["p-fn"];
      }
      if (queryParams["p-ln"]) {
        payload.person.lastName = queryParams["p-ln"];
      }
      if (queryParams["p-un"]) {
        payload.person.fullName = queryParams["p-un"];
      }
      if (queryParams["p-e"]) {
        payload.person.email = queryParams["p-e"];
      }

      if (payload.anonymousIds) {
        dispatch(createPublicPerson({ payload }));
      }
    }
  };

  keepAlive() {
    const { user, keepSessionAlive } = this.props;

    if (isLoggedIn() && user?.employerId) {
      keepSessionAlive(user.employerId);

      // Extend the login-related cookies too
      Cookies.set(isLoggedInCookieName, "true", {
        domain: cookieDomain,
        expires: 365,
      });
      if (Cookies.get(isImpersonatingCookieName) === "true") {
        Cookies.set(isImpersonatingCookieName, "true", {
          domain: cookieDomain,
          expires: 365,
        });
      }
    }
  }

  render() {
    return (
      <>
        <GlobalStyle />
        <PuckToastContainer />
        <BrowserRouter>
          <Suspense fallback={null}>
            <FragmentSupportingSwitch>
              <PrivateRoute
                exact
                path="/auth/lever/callback"
                component={LeverCallback}
              />
              <PrivateRoute
                exact
                path="/auth/outlook/callback"
                component={OutlookCallback}
              />
              <Route path="/login">
                <Redirect to="/employers/login" />
              </Route>
              {/* <Route path="/test" component={Test} /> */}
              <Route path="/employers/login" component={Login} />
              <Route path="/employers/claim-account" component={ClaimAccount} />
              <Route
                path="/employers/claim-account-signup"
                component={ClaimAccountSignup}
              />
              <Route
                path="/employers/claim-account-profile"
                component={ClaimAccountProfile}
              />
              <Route
                path="/employers/forgot-password"
                component={ForgotPassword}
              />
              <Route
                path="/employers/reset-password"
                component={ResetPassword}
              />
              <Route path="/logout" component={Logout} />
              <Route path="/analytics/block" component={AnalyticsBlock} />
              <PrivateRoute path="/impersonate" component={Impersonate} />
              <PrivateRoute path={["/", "/employers"]} component={Home} exact />

              {/* Embed */}
              <Route
                path={[
                  "/job-clip/:jobClipPermalink/track/:jobClipTrackPermalink/embed",
                  "/job-clip/:jobClipPermalink/embed",
                ]}
                component={Embed}
              />

              {/* Native Share Flows */}
              <Route
                path={[
                  "/job-clip/:jobClipPermalink/native-social-share",
                  "/job-board/:jobBoardPermalink/job/:jobPagePermalink/native-social-share",
                ]}
                component={NativeSocialShare}
              />

              {/* Job Clips */}
              <Route
                path="/job-clip/:jobClipPermalink/transcript"
                component={JobClipTranscriptPublic}
              />
              <Route
                path="/job-clip/:jobClipPermalink"
                component={JobClipPublic}
              />

              {/* Job Boards */}
              <Route
                path="/job-board/:jobBoardPermalink/job/:jobPagePermalink/apply"
                component={JobPageApplicationPublic}
              />
              <Route
                path="/job-board/:jobBoardPermalink/job/:jobPagePermalink"
                component={JobPagePublic}
              />
              <Route
                path="/job-board/:jobBoardPermalink"
                component={JobBoardPublic}
              />

              {/* Content Pages */}
              <Route
                path="/page/:contentPagePermalink"
                component={ContentPagePublic}
              />

              {/* Sales */}
              <Route
                path="/sales/:employerPermalink/page/:contentPagePermalink"
                component={ContentPagePublicSales}
              />

              {/* Employer Sharing */}
              <PrivateRoute
                path={[
                  "/employers/sourcing-page/:sourcingPagePermalink/share-link/:shareLinkPermalink",
                  "/employers/interview-guide/:interviewGuidePermalink/share-link/:shareLinkPermalink",
                  "/employers/other-page/:sourcingPagePermalink/share-link/:shareLinkPermalink",
                  "/employers/job-page/:jobPagePermalink/share-link/:shareLinkPermalink",
                  "/employers/content-library/podcast/:jobClipPermalink/share-link/:shareLinkPermalink",
                ]}
                component={ShareLinkDetail}
              />

              {/* Employer Podcasts */}
              <PrivateRoute
                path="/employers/content-library/podcast/:jobClipPermalink/edit"
                component={DashboardJobClipEdit}
              />
              <PrivateRoute
                path={"/employers/content-library/podcast/:jobClipPermalink"}
                component={DashboardJobClipDetail}
              />
              <PrivateRoute
                path={"/employers/content-library"}
                component={DashboardContentLibrary}
              />

              {/* Employer Job Pages */}
              <PrivateRoute
                path={"/employers/job-page/:jobPagePermalink"}
                component={DashboardJobPageDetail}
              />
              <PrivateRoute
                path={"/employers/job-page"}
                component={DashboardJobPages}
              />

              {/* Employer Leads */}
              <PrivateRoute
                path={
                  "/employers/lead/screening-question/:screeningSetPermalink/edit"
                }
                component={DashboardScreeningQuestionsEdit}
              />
              <PrivateRoute
                path={"/employers/lead/screening-question/new"}
                component={DashboardScreeningQuestionsEdit}
              />
              <PrivateRoute
                path={"/employers/lead/screening-question"}
                component={DashboardScreeningQuestions}
              />
              <PrivateRoute
                path={"/employers/lead"}
                compoent={DashboardLeads}
              />

              {/* Employer Text-to-Apply */}
              <PrivateRoute
                path={"/employers/text-to-apply"}
                component={DashboardTextToApply}
              />

              {/* Employer Smart Links */}
              {/* <PrivateRoute
                path={"/employers/smart-link"}
                component={DashboardSmartLinks}
              /> */}

              {/* Employer Video Clips */}
              <PrivateRoute
                path={"/employers/video-clip/edit"}
                component={DashboardTeaserEdit}
              />
              <PrivateRoute
                path={"/employers/video-clip"}
                component={DashboardTeasers}
              />

              {/* Employer Talent Pool */}
              <PrivateRoute
                path={"/employers/talent-pool/person/:personPermalink"}
                component={DashboardTalentPool}
              />
              <PrivateRoute
                path={"/employers/talent-pool"}
                component={DashboardTalentPool}
              />

              {/* Employer Talent Lists */}
              <PrivateRoute
                path={"/employers/talent-list/:personListPermalink"}
                component={DashboardPersonListDetail}
              />
              <PrivateRoute
                path={"/employers/talent-list"}
                component={DashboardTalentLists}
              />

              {/* Employer Sequences */}
              <PrivateRoute
                path={"/employers/sequence/:sequencePermalink/edit"}
                component={DashboardSequenceEdit}
              />
              <PrivateRoute
                path={"/employers/sequence/:sequencePermalink"}
                component={DashboardSequenceDetail}
              />
              <PrivateRoute
                path={"/employers/sequence"}
                component={DashboardSequences}
              />

              {/* Employer Content Pages */}
              <PrivateRoute
                path="/employers/page/:contentPagePermalink/preview"
                component={SourcingPagePreview}
              />
              <PrivateRoute
                path="/employers/page/:contentPagePermalink/edit"
                component={ContentPageEdit}
              />
              <PrivateRoute
                path="/employers/page/sourcing-page-wizard"
                component={SourcingPageWizard}
              />
              <PrivateRoute
                path={["/employers/settings/email"]}
                component={DashboardSettingsEmail}
              />
              <PrivateRoute
                path="/employers/podcast/job-clip/:jobClipPermalink/edit"
                component={DashboardJobClipEdit}
              />
              <PrivateRoute
                path={"/employers/sourcing-page/:sourcingPagePermalink"}
                component={SourcingPageDetail}
              />
              <PrivateRoute
                path="/employers/sourcing-page"
                component={SourcingPages}
              />
              <PrivateRoute
                path={"/employers/interview-guide/:interviewGuidePermalink"}
                component={InterviewGuideDetail}
              />
              <PrivateRoute
                path="/employers/interview-guide"
                component={InterviewGuides}
              />
              <PrivateRoute
                path={
                  "/employers/content-page-job-page/:contentPageJobPagePermalink"
                }
                component={ContentPageJobPageDetail}
              />
              <PrivateRoute
                path="/employers/content-page-job-page"
                component={ContentPageJobPages}
              />
              <PrivateRoute
                path={"/employers/other-page/:otherPagePermalink"}
                component={OtherPageDetail}
              />
              <PrivateRoute
                path="/employers/other-page"
                component={OtherPages}
              />

              {/* Employer Settings */}
              <PrivateRoute
                path={"/employers/settings/profile"}
                component={DashboardSettingsProfile}
              />
              <PrivateRoute
                path={"/employers/settings/ats"}
                component={DashboardSettingsAts}
              />
              <PrivateRoute
                path={"/employers/settings/members"}
                component={DashboardSettingsMembers}
              />
              <PrivateRoute
                path={"/employers/settings/smart-links"}
                component={DashboardSettingsSmartLinks}
              />
              <PrivateRoute
                path={"/employers/settings/application-reminders"}
                component={DashboardSettingsApplicationReminders}
              />
              <PrivateRoute path={"/employers/settings"}>
                <Redirect to="/employers/settings/ats" />
              </PrivateRoute>

              {/* Employer PDF Reports */}
              <PrivateRoute
                path="/employers/:employerPermalink/pdf-report/:pdfReportPermalink/download"
                component={PdfReportDownload}
              />

              {/* App Reminders */}
              <Route
                path="/app-reminder/opt-out/:sequenceTargetPermalink"
                component={AppReminderOptOut}
              />

              {/* Salary Calculator */}
              <Route
                path="/salary-calculator/link/:permalink"
                component={SalaryCalculatorResultsLinked}
              />
              <Route
                path="/salary-calculator"
                component={SalaryCalculatorResults}
              />

              {(appStage === "uat" || nodeEnv === "development") && (
                <>
                  <Route path="/__env" component={ShowEnv} />
                  <Route path="/__error" component={ErrorTest} />
                  <Route path="/__unauthorized" component={Unauthorized} />
                </>
              )}
              <Route path="/404" component={Error404} />
              <Route component={Error404} />
            </FragmentSupportingSwitch>
          </Suspense>
        </BrowserRouter>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;
  return {
    user: auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        keepSessionAlive,
        linkPublicPerson,
        createPublicPerson,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
