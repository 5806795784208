import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "utils/api";
import { appApiEndpoint } from "environment";
import { flattenTree } from "utils/helpers";
import sortBy from "lodash/sortBy";

export const createJobClip = createAsyncThunk(
  "jobs/createJobClip",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const payload = arg.payload;

    const withCredentials = true;
    try {
      const response = await api.post(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/job-clips`,
        {},
        payload
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateJobClip = createAsyncThunk(
  "jobs/updateJobClip",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const jobClipPermalink = arg.jobClipPermalink;
    const payload = arg.payload;

    const withCredentials = true;
    try {
      const response = await api.put(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/job-clips/${jobClipPermalink}`,
        {},
        payload
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const duplicateJobClip = createAsyncThunk(
  "jobs/duplicateJobClip",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const jobClipPermalink = arg.jobClipPermalink;
    const payload = arg.payload;

    const withCredentials = true;
    try {
      const response = await api.post(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/job-clips/${jobClipPermalink}/duplicate`,
        {},
        payload
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadPublicJobClip = createAsyncThunk(
  "jobs/loadPublicJobClip",
  async (arg, thunkAPI) => {
    const withCredentials = false;
    try {
      const response = await api.get(
        withCredentials,
        `${appApiEndpoint}/v1/public/job-clips/${arg}`,
        {},
        {},
        null,
        false
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadPublicJobPage = createAsyncThunk(
  "jobs/loadPublicJobPage",
  async (arg, thunkAPI) => {
    const jobBoardPermalink = arg.jobBoardPermalink;
    const jobPagePermalink = arg.jobPagePermalink;

    const withCredentials = false;
    try {
      const response = await api.get(
        withCredentials,
        `${appApiEndpoint}/v1/public/job-boards/${jobBoardPermalink}/jobs/${jobPagePermalink}`,
        {},
        {},
        null,
        false
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadJobClips = createAsyncThunk(
  "jobs/loadJobClips",
  async (arg, thunkAPI) => {
    const withCredentials = true;
    try {
      const response = await api.get(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${arg}/job-clips?perPage=1000`,
        {}
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadJobClipsPaginated = createAsyncThunk(
  "jobs/loadJobClipsPaginated",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const perPage = arg.perPage;
    const cursor = arg.cursor;
    const searchQuery = arg.searchQuery;

    let url = `${appApiEndpoint}/v1/employers/${employerPermalink}/job-clips?perPage=${perPage}`;
    if (cursor) {
      url += `&cursor=${cursor}`;
    }

    if (searchQuery) {
      url += `&search=${searchQuery}`;
    }

    const withCredentials = true;
    try {
      const response = await api.get(withCredentials, url, {});
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadJobClip = createAsyncThunk(
  "jobs/loadJobClip",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const jobClipPermalink = arg.jobClipPermalink;

    const withCredentials = true;
    try {
      const response = await api.get(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/job-clips/${jobClipPermalink}`,
        {}
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadJobClipMetrics = createAsyncThunk(
  "jobs/loadJobClipMetrics",
  async (arg, thunkAPI) => {
    const withCredentials = true;
    try {
      const response = await api.get(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${arg}/job-clips/metrics`,
        {}
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getJobClipFromJobClipPermalink = (jobClipPermalink, state) => {
  const { jobs } = state;

  return jobs?.myJobClips?.byPermalink[jobClipPermalink];
};

export const loadPublicJobBoard = createAsyncThunk(
  "jobs/loadPublicJobBoard",
  async (arg, thunkAPI) => {
    const withCredentials = false;
    try {
      const jobBoard = await api.get(
        withCredentials,
        `${appApiEndpoint}/v1/public/job-boards/${arg}`,
        {},
        {},
        null,
        false
      );

      return augmentJobBoard(jobBoard);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadJobBoard = createAsyncThunk(
  "jobs/loadJobBoard",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const jobBoardPermalink = arg.jobBoardPermalink;

    const withCredentials = true;
    try {
      const jobBoard = await api.get(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/job-boards/${jobBoardPermalink}`
      );
      return augmentJobBoard(jobBoard);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadJobBoards = createAsyncThunk(
  "jobs/loadJobBoards",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const jobBoardPermalinks = arg.jobBoardPermalinks;

    const withCredentials = true;
    try {
      // Make a separate call for each job board and return the results
      const jobBoards = await Promise.all(
        jobBoardPermalinks.map((jobBoardPermalink) =>
          api.get(
            withCredentials,
            `${appApiEndpoint}/v1/employers/${employerPermalink}/job-boards/${jobBoardPermalink}`
          )
        )
      );

      return jobBoards.map((jobBoard) => augmentJobBoard(jobBoard));
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addHeartToJobClipHighlightPublic = createAsyncThunk(
  "jobs/addHeartToJobClipHighlightPublic",
  async (arg, thunkAPI) => {
    const withCredentials = false;
    try {
      const response = await api.post(
        withCredentials,
        `${appApiEndpoint}/v1/public/job-clip-highlights/${arg}/addHeart`
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadJobPage = createAsyncThunk(
  "jobs/loadJobPage",
  async (arg, thunkAPI) => {
    const employerPermalink = arg.employerPermalink;
    const jobPagePermalink = arg.jobPagePermalink;

    const withCredentials = true;
    try {
      const response = await api.get(
        withCredentials,
        `${appApiEndpoint}/v1/employers/${employerPermalink}/job-pages/${jobPagePermalink}`,
        {}
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const augmentJobBoard = (jobBoard) => {
  // Create 2-deep list of nested departments, for rendering the job-row
  jobBoard.flatDepartments = jobBoard.departments.map((department) => {
    return {
      ...department,
      flatChildren: flattenTree(
        department.children.map((c) => {
          return {
            ...c,
            parent: department,
          };
        }),
        (root) => {
          return root.children;
        }
      ),
    };
  });

  // Create 2-deep list of nested offices, for rendering the job-row
  jobBoard.flatOffices = jobBoard.offices.map((office) => {
    return {
      ...office,
      flatChildren: flattenTree(
        office.children.map((c) => {
          return {
            ...c,
            parent: office,
          };
        }),
        (root) => {
          return root.children;
        }
      ),
    };
  });

  // Add top-level department and office to each job, for filtering
  jobBoard.jobs = jobBoard.jobs.map((job) => {
    const jobDepartmentPermalinks = job.departments.map((d) => d.permalink);
    const jobOfficePermalinks = job.offices.map((o) => o.permalink);
    return {
      ...job,
      topDepartment: jobBoard.flatDepartments.find((top) => {
        if (jobDepartmentPermalinks.includes(top.permalink)) return top;
        return !!top.flatChildren.find((c) =>
          jobDepartmentPermalinks.includes(c.permalink)
        );
      }),
      topOffice: jobBoard.flatOffices.find((top) => {
        if (jobOfficePermalinks.includes(top.permalink)) return top;
        return !!top.flatChildren.find((c) =>
          jobOfficePermalinks.includes(c.permalink)
        );
      }),
    };
  });

  // Add jobCount to top-level departments and flatChildren
  jobBoard.flatDepartments = jobBoard.flatDepartments.map((department) => {
    const flatChildren = department.flatChildren.map((child) => {
      // Check each department in the departments array
      const jobCount = jobBoard.jobs.filter((j) => {
        return j.departments.find((d) => d.permalink === child.permalink);
      }).length;

      return {
        ...child,
        jobCount,
      };
    });

    return {
      ...department,
      jobCount:
        jobBoard.jobs.filter((j) =>
          j.departments.map((d) => d.permalink).includes(department.permalink)
        ).length + flatChildren.reduce((acc, c) => acc + c.jobCount, 0),
      flatChildren,
    };
  });

  // Add jobCount to top-level offices and flatChildren
  jobBoard.flatOffices = jobBoard.flatOffices.map((office) => {
    const flatChildren = office.flatChildren.map((child) => {
      // Check each office in the offices array
      const jobCount = jobBoard.jobs.filter((j) => {
        return j.offices.find((d) => d.permalink === child.permalink);
      }).length;

      return {
        ...child,
        jobCount,
      };
    });

    return {
      ...office,
      jobCount:
        jobBoard.jobs.filter((j) =>
          j.offices.map((o) => o.permalink).includes(office.permalink)
        ).length + flatChildren.reduce((acc, c) => acc + c.jobCount, 0),
      flatChildren,
    };
  });

  // console.log(jobBoard.flatDepartments);
  // console.log(jobBoard.flatOffices);
  // console.log(jobBoard.jobs);

  return jobBoard;
};

// An entity being either a department or location
export const getFlatEntitiesWithJobs = (flatEntities) => {
  let flatEntitiesWithJobs = flatEntities.filter((d) => d.jobCount > 0);
  // Filter out any empty sub-departments
  flatEntitiesWithJobs = flatEntitiesWithJobs.map((d) => {
    let flatChildren = d.flatChildren.filter((child) => child.jobCount > 0);
    flatChildren = sortBy(flatChildren, "name");
    return {
      ...d,
      flatChildren,
    };
  });
  flatEntitiesWithJobs = sortBy(flatEntitiesWithJobs, "name");

  return flatEntitiesWithJobs;
};

export const initialState = {
  savingJobClip: false,
  saveJobClipError: false,
  saveJobClipSuccess: false,
  loadingPublicJobClip: false,
  loadingJobClips: false,
  loadingPublicJobBoard: false,
};

const jobsSlice = createSlice({
  name: "jobs",
  initialState: initialState,
  reducers: {
    clearFlags(state) {
      state = { ...state, ...initialState };
    },
  },
  extraReducers: {
    "auth/logout/fulfilled": (state, action) => {
      return initialState;
    },
    [createJobClip.pending]: (state, action) => {
      state["savingJobClip"] = false;
      state["saveJobClipSuccess"] = false;
      state["saveJobClipError"] = false;
    },
    [createJobClip.fulfilled]: (state, action) => {
      const jobClip = action.payload;

      state["myJobClips"].byPermalink[jobClip.permalink] = jobClip;
      state["myJobClips"].allPermalinks.push(jobClip.permalink);

      state["savingJobClip"] = false;
      state["saveJobClipSuccess"] = true;
      state["saveJobClipError"] = false;
    },
    [createJobClip.rejected]: (state, action) => {
      state["savingJobClip"] = false;
      state["saveJobClipSuccess"] = false;
      state["saveJobClipError"] = true;
    },
    [updateJobClip.pending]: (state, action) => {
      state["savingJobClip"] = false;
      state["saveJobClipSuccess"] = false;
      state["saveJobClipError"] = false;
    },
    [updateJobClip.fulfilled]: (state, action) => {
      const jobClip = action.payload;

      state["myJobClips"].byPermalink[jobClip.permalink] = jobClip;

      state["savingJobClip"] = false;
      state["saveJobClipSuccess"] = true;
      state["saveJobClipError"] = false;
    },
    [updateJobClip.rejected]: (state, action) => {
      state["savingJobClip"] = false;
      state["saveJobClipSuccess"] = false;
      state["saveJobClipError"] = true;
    },
    [duplicateJobClip.pending]: (state, action) => {
      state["savingJobClip"] = false;
      state["saveJobClipSuccess"] = false;
      state["saveJobClipError"] = false;
    },
    [duplicateJobClip.fulfilled]: (state, action) => {
      const jobClip = action.payload;

      state["myJobClips"].allPermalinks.push(jobClip.permalink);
      state["myJobClips"].byPermalink[jobClip.permalink] = jobClip;

      state["savingJobClip"] = false;
      state["saveJobClipSuccess"] = true;
      state["saveJobClipError"] = false;
    },
    [duplicateJobClip.rejected]: (state, action) => {
      state["savingJobClip"] = false;
      state["saveJobClipSuccess"] = false;
      state["saveJobClipError"] = true;
    },
    [loadPublicJobClip.pending]: (state, action) => {
      state["loadingPublicJobClip"] = true;
    },
    [loadPublicJobClip.fulfilled]: (state, action) => {
      state["loadingPublicJobClip"] = false;
    },
    [loadPublicJobClip.rejected]: (state, action) => {
      state["loadingPublicJobClip"] = false;
    },
    [loadJobClips.pending]: (state, action) => {
      state["loadingJobClips"] = true;
    },
    [loadJobClips.fulfilled]: (state, action) => {
      state["myJobClips"] = {
        byPermalink: {},
        allPermalinks: [],
      };
      action.payload.records.forEach((jobClip) => {
        state["myJobClips"].byPermalink[jobClip.permalink] = jobClip;
        state["myJobClips"].allPermalinks.push(jobClip.permalink);
      });

      state["loadingJobClips"] = false;
    },
    [loadJobClips.rejected]: (state, action) => {
      state["loadingJobClips"] = false;
    },
  },
});

export const { clearFlags } = jobsSlice.actions;

export default jobsSlice.reducer;
