import "index.css";
import "core-js/es/object/values";

import * as Sentry from "@sentry/react";
import * as serviceWorker from "serviceWorker";

import { appStage, googleClientId, nodeEnv } from "environment";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import App from "App";
import GitInfo from "react-git-info/macro";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import SocketProvider from "context/SocketContext";
import { injectStore } from "./utils/api";
import { logoutClient } from "slices/auth";
import rootReducer from "reducers";

const enhancers = [];
if (nodeEnv === "production") {
  enhancers.push(
    Sentry.createReduxEnhancer({
      stateTransformer: (state) => {
        const transformedState = {
          ...state,
        };

        // remove bootstrap so we don't use up extra payload kbs
        delete transformedState.bootstrap;

        return transformedState;
      },
    })
  );
}

const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware()],
  enhancers: enhancers,
});

injectStore(store);

const gitInfo = GitInfo();

if (nodeEnv === "production") {
  Sentry.init({
    dsn: "https://aae548f3ce8e436cae012d07ed0c2626@o375835.ingest.sentry.io/5195866",
    release: gitInfo.commit.shortHash,
    environment: appStage || nodeEnv,
    integrations: [
      new Integrations.BrowserTracing(),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        // maskAllText: true,
        // blockAllMedia: true,
      }),
    ],
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.2,
    normalizeDepth: 6, // depth of redux state to capture, default=3
    maxBreadcrumbs: 25,
  });
}

function ErrorFallback() {
  return <div>An error has occurred</div>;
}
const errorFallback = <ErrorFallback />;

const Root = () => (
  <Provider store={store}>
    <GoogleOAuthProvider clientId={googleClientId}>
      <SocketProvider>
        <Sentry.ErrorBoundary fallback={errorFallback} showDialog>
          <App />
        </Sentry.ErrorBoundary>
      </SocketProvider>
    </GoogleOAuthProvider>
  </Provider>
);

// ReactDOM.render(<App />, document.getElementById("root"));
ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const bc = new BroadcastChannel("logout_channel");
bc.onmessage = (event) => {
  if (event.data === "logout") {
    // Perform logout action
    // For example, redirect the user to the logout page
    store.dispatch(logoutClient());
  }
};
